<template>
    <div class="gui-page">
      <div class="banner">
        <div style="width: 1200px; height: 100%; margin: 0 auto; display: flex; align-items: center;  justify-content: space-between;">
            <img src="https://resources.holowits.com.sg/wiki/wikilogo4.png" alt="">
            <div class="search-bar">
                <i class="el-icon-search search-icon"/>
                <input class="search-input" type="text" :placeholder="$i18n.t('news.searchPlaceholder')" v-model="keywords" @keyup.enter="search">
                <div class="search-button" @click="search">{{ $i18n.t('news.search') }}</div>
            </div>
        </div>
      </div>
      <div class="content">
          <div class="content-left">
              <ul class="nav-group">
                  <li class="nav-group-item" v-for="item in list" :key="item.label">
                      <div class="nav-group-item-label"><span>{{ item.label }}</span></div>
                      <ul class="nav-item">
                          <li :class="{'isActive': active === item2.correspond}" v-for="item2 in item.item" :key="item2.name" @click="jump(item2.url)">{{ item2.name }}</li>
                      </ul>
                  </li>
              </ul>
          </div>
          <div class="content-right">
            <div class="content-box">
                <div v-for="(item, index) in searchResults" :key="index" class="result">
                    <div class="result-title"><a :href="item.url" v-html="item.title"></a></div>
                    <!-- <div class="result-introductory"><p v-html="item.introductory"></p></div> -->
                </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import {searchWikiContent} from "@/api/wikiApi"
export default {
name: 'SearchIndex',
data() {
    return {
        keywords: '',
        searchResults: [],
        active: '',
        list: [
            {
                label: 'Operation Guideline',
                item: [
                    {
                        name: 'AI Camera',
                        correspond: '_01',
                        url: '/holowitswiki/AI Camera'
                    },
                    {
                        name: 'Video Intelligent Platform',
                        correspond: '_02',
                        url: '/holowitswiki/Video Intelligent Platform'
                    },
                    {
                        name: 'IP Camera & Analog Camera',
                        correspond: '_03',
                        url: '/holowitswiki/IP Camera & Analog Camera'
                    },
                    {
                        name: 'NVR & XVR',
                        correspond: '_04',
                        url: '/holowitswiki/NVR & XVR'
                    },
                    {
                        name: 'Software',
                        correspond: '_05',
                        url: '/holowitswiki/Software'
                    },
                    {
                        name: 'Cloud Service',
                        correspond: '_06',
                        url: '/holowitswiki/Cloud Service'
                    },
                    {
                        name: 'Solution',
                        correspond: '_07',
                        url: '/holowitswiki/Solution'
                    },
                    {
                        name: 'Other',
                        correspond: '_08',
                        url: '/holowitswiki/Other'
                    },
                ]
            },
        ],
    }
},
mounted() {
    let keywords = this.$route.query.keywords;
    this.keywords  = keywords;
    this.search();
},
methods: {
    jump(url) {
          this.$router.push({
            path: url
          })
      },
    async search() {
        const {data: {data, isSuccess}} = await searchWikiContent({
            value: this.keywords
        })
        if (isSuccess) {
            // 把含有关键字的替换成粗体
            for (let item of data) {
                item.title = item.title.replaceAll(this.keywords, `<strong style="color: #c7000b">${this.keywords}</strong>`);
                if (item.introductory) {
                    item.introductory = item.introductory.replaceAll(this.keywords, `<strong style="color: #c7000b">${this.keywords}</strong>`);
                }
            }
            this.searchResults = data;
        }
    },
}
}
</script>

<style lang="less" scoped>
  .gui-page {
      background-color: #f7fafb;
  }
  .banner {
      height: 160px;
      background-image: url('https://resources.holowits.com.sg/wiki/wiki_banner.png');
      padding-left: 30px;
      img {
          width: 160px;
          height: 160px;
          // margin: 5px 0;
      }
      .search-bar {
        width: 80%;
        max-width: 750px;
        background-color: #FFFFFF;
        height: 50px;
        border-radius: 48px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        z-index: 2;
        box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.08);

        .search-icon {
            font-size: 24px;
            margin: 0 12px 0 24px;
            color: #999999;
            position: relative;
            z-index: 10;
        }

        .search-input {
            flex: 1;
            width: 100%;
            height: 50px;
            box-shadow: none;
            outline: none;
            font-size: 18px;
            font-family: "Microsoft Yahei", sans-serif;
            padding-left: 60px;
            margin-left: -55px;
            border-radius: 48px 0 0 48px;
            border: 1px solid transparent;
            position: relative;
            z-index: 3;
            color: #333333;

            &:focus + .search-icon {
                color: #c7000b;
            }
        }

        .search-button {
            cursor: pointer;
            color: #FFFFFF;
            font-size: 16px;
            background-color: var(--themeColor);
            height: 100%;
            width: 80px;
            padding-right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;

            &:active {
                opacity: .8;
            }
        }
    }
  }
  .content {
      display: flex;
      width: 1600px;
      margin: 0 auto;
      margin-top: 20px;
      .content-left {
          // background-color: white;
          position: fixed;
          width: 300px;
          // padding: 20px;
          // padding: 20px 0px 20px 20px;
          // margin-right: 20px;
          .nav-group {
              // background-color: white;
              padding: 20px 0px 20px 20px;
              margin-right: 20px;
              .nav-group-item {
                  &+.nav-group-item {
                      margin-top: 20px;
                  }
                  .nav-group-item-label {
                      font-size: 18px;
                      font-weight: 600;
                      margin-bottom: 50px;
                      span {
                          padding-bottom: 25px;
                          border-bottom: 1px solid #c7000b;
                      }
                  }
                  .nav-item {
                      margin-left: 10px;
                      li {
                          list-style: disc;
                          height: 30px;
                          line-height: 30px;
                          cursor: pointer;
                          padding-left: 5px;
                          transition: all 0.3s;
                          &:hover {
                              list-style: none;
                              color: white;
                              background-color: #c7000b;
                          }
                      }
                      li + li {
                          margin-top: 7px;
                      }
                      .isActive {
                          color: white;
                          background-color: #c7000b;
                      }
                  }
              }
          }
      }
      .content-right {
          background-color: white;
          padding: 20px;
          margin-bottom: 100px;
          width: 1300px;
          margin-left: 300px;

          .content-box {
            width: 100%;
            min-height: 600px;

            .result {
                .result-title {
                    font-size: 19px;
                    a {
                        color: #0000ff !important;
                        text-decoration: revert !important;
                    }
                }
                .result-introductory {
                    margin-top: 5px;
                    font-size: 14px;
                }
            }
            .result + .result {
                margin-top: 20px;
            }
          }
          .product-series {
              & + .product-series {
                  margin-top: 50px;
              }
              .series-title {
                  font-size: 24px;
                  border-bottom: 1px solid gray;
              }
              .series-list {
                  display: flex;
                  flex-wrap: wrap;
  
                  .series-item {
                      position: relative;
                      margin-right: 20px;
                      &:nth-child(4n) {
                          margin-right: 0px;
                      }
                      &:hover {
                          cursor: pointer;
                          color: #c7000b;
                      }
  
                      width: 200px;
                      // flex-shrink: 0;
                      .series-item-img {
                          width: 100%;
                          img {
                              width: 100%;
                              height: 200px;
                              object-fit: contain;
                          }
                      }
                      .series-item-label {
                          position: absolute;
                          width: 100%;
                          bottom: 10px;
                          // margin-top: -20px;
                          height: 20px;
                          line-height: 20px;
                          text-align: center;
                          z-index: 2;
                      }
                      &:hover .series-item-label span {
                          border-bottom: 1px solid #c7000b;
                      }
                  }
              }
          }
      }
  }
  </style>